<template>
  <div class="p-4 bg-white rounded-lg">
    <DataTable />
  </div>
</template>

<script>
import DataTable from "@/own/components/customerService/callSheet/DataTable.vue";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/callSheet.module";
export default {
  name: "CallSheet",
  components: { DataTable },
  mounted() {
    this.$store.commit(SET_PAGE_LOADING, true);
    this.$store.dispatch(UPDATE_TABLE_STRUCTURE, {});
    this.$store.dispatch(
      UPDATE_TABLE_DATA,
      this.$store.getters.getCALLSHEETTableState
    );
  },
};
</script>
